import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import "../styles/film-list.scss"

const BlogPage = ({ data }) => {
    const {edges} = data.allMarkdownRemark
    return(
        <Layout>
            <Helmet title="Films | ShortOrme Productions" />
            <h2>Films</h2>
            <div id="film-list">
              {edges.map(edge => {
                  const {frontmatter, fields} = edge.node
                  return(
                      <div class="film">
                          <a href={fields.slug}>
                            <h3 key={fields.slug}>                            
                              {frontmatter.title}
                              {frontmatter.year !== null && <span> ({frontmatter.year})</span>}  
                            </h3>
                          </a>
                          {frontmatter.status === 'inproduction' && <p class="in-production">In Production</p>}
                          <Img className="film-img" fluid={frontmatter.featuredimage.childImageSharp.fluid} alt="Featured image" />
                      </div>
                  )
              })}
            </div>
        </Layout>
    )
}

export default BlogPage

export const query = graphql`
query {
    allMarkdownRemark(
        sort: {order: DESC, fields: frontmatter___date}
        filter: {fields: {slug: {glob: "/films/*"}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            director
            status
            year
            synopsis
            featuredimage{
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }    
          }
          fields {
            slug
          }          
        }
      }
    }
  }
`